/* eslint-disable sonarjs/cognitive-complexity, react/forbid-component-props */
import { faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Formik } from 'formik';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Cookies from 'react-cookies';
import { useSelector, useDispatch } from 'react-redux';
import { object, string } from 'yup';
import { PAGE_TYPES } from '../../config/constants/page-types';
import COOKIES from '../../config/cookies/cookies';
import {
  LINK_FORGOT_PASSWORD,
  LINK_MAIN_HOME,
  LINK_MY_VOUCHERS,
} from '../../config/links/links';
import {
  BRAND_WOWCHER,
  COOKIE_SUBSCRIBED_EXPIRES,
} from '../../config/setup/setup';
import {
  LOGIN,
  FORM_EMAIL,
  FORM_PASSWORD,
  ENTER_VALID_EMAIL,
  PASSWORD_SIX_CHARACTERS,
  REQUIRED_FIELD,
  USER_DOESNT_EXISTS,
  AN_ERROR_OCCURED,
  LOCKED_ACCOUNT,
  SIGN_IN_WITH_FACEBOOK_ONLY,
  REGISTER_PRIVACY_POLICY,
  LOGIN_PRIVACY_POLICY_CHECKOUT,
  LOGIN_PRIVACY_POLICY,
  INVALID_CREDENTIALS,
} from '../../config/text/text';
import { trackEvent } from '../../helpers/analytics';
import { setCookie } from '../../helpers/cookieSetter';
import ErrorTrackingService from '../../helpers/errorTrackingService';
import { unsubscribeAllUser } from '../../helpers/unsubscribe';
import { getUserStatus } from '../../helpers/userStatus';
import { setUserEmail } from '../../redux/actions/email';
import { setLoadingState } from '../../redux/actions/loading';
import { logUser, loginSuccess } from '../../redux/actions/user';
import { LOADING_STATUS } from '../../redux/reducers/loading';
import Message from '../_generic/Message';
import { sendBotAlert } from '../_generic/axiosSplunk/axiosSplunk';
import ButtonForm from '../_generic/button/CTAButton';
import FormikInput from '../_generic/input/FormikInput';
import TextDevider from '../_generic/textDevider/TextDevider';
import useToast from '../_generic/toast/UseToast';
import { panel } from '../checkout/details/UserPanelSetup';
import DeleteAccountMessage from './DeleteAccountMessage';
import { DisableAutofill } from './DisableAutofill';
import ForgotPassword from './ForgotPassword';
import LoginMediaButtons from './LoginMediaButtons';
import PrivacyText from './PrivacyText';

const schema = object().shape({
  email: string().required(REQUIRED_FIELD).email(ENTER_VALID_EMAIL),
  password: string().required(REQUIRED_FIELD).min(6, PASSWORD_SIX_CHARACTERS),
});

const LoginForm = ({
  isLoginPage = false,
  setActivePanel,
  enableAutofill = false,
  vipPaywall,
  expressCheckout,
  emailProperty = '',
}) => {
  const Environment = process.env.NEXT_PUBLIC_BRAND || BRAND_WOWCHER;
  const isDevelopment = process.env.NEXT_PUBLIC_ENVIRONMENT === 'dev';
  const toast = useToast();
  const dispatch = useDispatch();
  const [userEmail] = useSelector((state) => [state.email]);
  const [botField, setBotField] = useState('');
  const router = useRouter();
  const [isLocked, setIsLocked] = useState(false);
  const [isFBAccount, setIsFBAccount] = useState(false);
  const [emailOpt, setEmailOpt] = useState(false);
  const [isCheckout, setIsCheckout] = useState(false);
  const loginActionURL = router.asPath.includes('/login')
    ? '/login'
    : `/login?redirect=${router.asPath}`;
  const expressCheckoutUrl = router.query?.orderId
    ? `/login?redirect=${LINK_MY_VOUCHERS}?showProgressBar=true&orderId=${router.query?.orderId}&hasBenefits=${router.query?.hasBenefits}`
    : '';
  const actionURL = router.asPath.includes('signin-page')
    ? expressCheckoutUrl
    : loginActionURL;
  const failedLogin = router.asPath.includes('login-failed');

  const isDeleteAccount = Cookies.load(COOKIES.deleteEmail);

  const showErrorToast = (message) => toast.addToast(message, 'toast-error');

  const handleHPField = (event) => {
    setBotField(event.target.value);
  };

  useEffect(() => {
    const isCheckoutPage = router.asPath.includes(PAGE_TYPES.checkout);
    if (isCheckoutPage) setIsCheckout(true);
  }, []);

  useEffect(() => {
    setCookie({ key: COOKIES.emailOptOut, value: false });
    if (failedLogin) {
      showErrorToast(INVALID_CREDENTIALS);
    }
  }, []);

  useEffect(() => {
    const emailToggle = document.querySelector('#emailToggle');
    const handler = () => {
      setEmailOpt(!emailOpt);
      setCookie({ key: COOKIES.emailOptOut, value: !emailOpt });
    };
    if (emailToggle) {
      emailToggle.addEventListener('click', handler);

      return () => {
        emailToggle.removeEventListener('click', handler);
      };
    }
  });

  const sendForm = async (values) => {
    if (
      botField.length > 0 ||
      document.querySelector('#alt_email').value.length > 0
    ) {
      router.push(LINK_MAIN_HOME);
      sendBotAlert(router);

      return;
    }

    dispatch(setLoadingState(LOADING_STATUS.LOADING));

    try {
      const response = await logUser(values.email, values.password);
      setIsLocked(response?.fieldErrors?.isUserAccountLocked);
      if (response.data?.customerToken) {
        dispatch(loginSuccess(response.data));
        trackEvent('log_in');
        if (vipPaywall) {
          trackEvent('viphub: paywall login');
        }
        setCookie(
          COOKIES.brandCookie,
          'registered_user',
          COOKIE_SUBSCRIBED_EXPIRES,
        );

        setCookie(COOKIES.userLoggedIn, true);
        setCookie(COOKIES.registered, true, COOKIE_SUBSCRIBED_EXPIRES);

        // if emailOpt is true we need to call unsubscribe from all emails endpoint
        if (emailOpt) {
          await unsubscribeAllUser(response.data?.customerToken);
        }
      } else {
        showErrorToast(response.message);
      }
    } catch (error) {
      ErrorTrackingService.logError(error);
      showErrorToast(error.message || AN_ERROR_OCCURED);
    }
    dispatch(setLoadingState(LOADING_STATUS.READY));
  };

  // Work around: This block can be removed when Google Chrome fix the
  // autofill="off" bug. Check DisableAutofill for details.
  const [isInputDisable, SetIsInputDisable] = useState(!enableAutofill);

  useEffect(() => {
    const interval = setInterval(() => {
      SetIsInputDisable(false);
      clearInterval(interval);
    }, 1_000);

    // Specify how to clean up after this effect:
    return function cleanup() {
      clearInterval(interval);
    };
  }, []);
  // End of the work around

  const setUserDetails = async (email) => {
    try {
      const response = await getUserStatus(email);
      if (response) {
        setIsFBAccount(response.status.isRegistered && response.status.FBO);
        if (response.isLocked) {
          setIsLocked(true);

          return;
        }
        if (!response.isNewUser) return;
        if (response.userEmail) dispatch(setUserEmail(response.userEmail));
        if (setActivePanel) setActivePanel(panel.registration);
        toast.addToast(USER_DOESNT_EXISTS, 'toast-info');
      }
    } catch (error) {
      ErrorTrackingService.logError(error);
      showErrorToast(error.message || AN_ERROR_OCCURED);
    }
  };

  const extraClasses =
    Environment === BRAND_WOWCHER ? '' : 'lowercase_placeholder';

  return (
    <>
      <LoginMediaButtons emailOptOut={emailOpt} isLoginPage={isLoginPage} />
      <TextDevider vipPaywall={vipPaywall} />
      {isLocked && (
        <Message
          data-testid="locked-account"
          html
          iconPosition="left"
          message={LOCKED_ACCOUNT(LINK_FORGOT_PASSWORD)}
          type="ERROR"
        />
      )}
      {isDeleteAccount && <DeleteAccountMessage email={isDeleteAccount} />}

      <Formik
        enableReinitialize
        initialValues={{
          email: emailProperty || userEmail.userEmail || '',
          password: '',
        }}
        onSubmit={async (values) => sendForm(values)}
        validateOnBlur
        validateOnChange
        validateOnMount
        validationSchema={schema}
      >
        {({
          handleChange,
          handleSubmit,
          touched,
          handleBlur,
          isSubmitting,
          isValid,
          values,
          errors,
        }) => {
          const blurFunction = (event) => {
            const value = event.target.value;
            const name = event.target.name;
            handleBlur(event);
            if (!value) return;
            if (name === 'password') trackEvent('login_password_field');
            if (name === 'email') trackEvent('login_email_field');
            if (name === 'email' && !errors.email) setUserDetails(value);
          };

          return (
            <form
              action={actionURL}
              method="POST"
              name="login-form"
              onSubmit={isDevelopment ? handleSubmit : null}
            >
              {!enableAutofill && <DisableAutofill />}
              <FormikInput
                dataqa="enterEmail"
                disable={isInputDisable} // Disable the field if coming from express checkout
                errorText={errors.email}
                extraClasses={extraClasses}
                icon={faEnvelope}
                inputError={errors.email}
                inputTouched={touched.email}
                inputValue={values.email}
                isCheckout={isCheckout}
                name={'email'}
                onBlurFunction={(event) => blurFunction(event)}
                onChangeFunction={handleChange}
                placeholder={FORM_EMAIL}
                readOnly={Boolean(emailProperty)}
                type="email"
              />
              <FormikInput
                dataqa="enterPassword"
                errorText={errors.password}
                extraClasses={extraClasses}
                icon={faLock}
                inputError={errors.password}
                inputTouched={touched.password}
                inputValue={values.password}
                isCheckout={isCheckout}
                name={'password'}
                onBlurFunction={(event) => blurFunction(event)}
                onChangeFunction={handleChange}
                placeholder={FORM_PASSWORD}
                type="password"
              />

              <input
                aria-label="alt email"
                autoComplete="off"
                className="hp-container"
                id="alt_email"
                name="alt_email"
                onChange={handleHPField}
                tabIndex="-1"
                type="text"
              />

              <ButtonForm
                className="full-width button"
                dataqa="login"
                disabled={isSubmitting || !isValid}
                type="submit"
              >
                {LOGIN}
              </ButtonForm>
              {isCheckout ? (
                <PrivacyText
                  text={LOGIN_PRIVACY_POLICY_CHECKOUT(
                    Environment,
                    emailOpt ? "- I'M OUT!" : '',
                  )}
                />
              ) : (
                <PrivacyText
                  text={
                    vipPaywall && !expressCheckout
                      ? REGISTER_PRIVACY_POLICY[Environment]
                      : LOGIN_PRIVACY_POLICY[Environment]
                  }
                />
              )}
              {isFBAccount && (
                <p className="facebook-login text-center">
                  <b>{SIGN_IN_WITH_FACEBOOK_ONLY}</b>
                </p>
              )}
              <ForgotPassword email={values.email} isFBOnly={isFBAccount} />
            </form>
          );
        }}
      </Formik>

      <style jsx>{`
        .facebook-login {
          font-size: 15px;
        }
        .hp-container {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          height: 0;
          width: 0;
          z-index: -1;
        }
      `}</style>
      <style global jsx>{`
        .form-control:disabled,
        .form-control[readonly] {
          background-color: #ffffff;
        }
        .email-trick input {
          color: #999999;
        }
      `}</style>
    </>
  );
};

LoginForm.propTypes = {
  emailProperty: PropTypes.string,
  enableAutofill: PropTypes.bool,
  isLoginPage: PropTypes.bool,
  setActivePanel: PropTypes.func,
  vipPaywall: PropTypes.bool,
};

export default LoginForm;
